import { getColorVar } from '@yumpingo/yummy-components/theme/Palette';
import React, { FC, PropsWithChildren } from 'react';
import { useSkipReview } from '../../pages/review/hooks/use-skip-review';
import { retrieveDecodedToken } from '../../utils/token-utils';
import ViewCheck from '../view-check/ViewCheck';
import classNames from 'classnames';
import { getBillId } from '../../utils/digi-bill';

import './BrandContainer.style.scss';
import { Redirect } from 'react-router-dom';
import { selectReviewConfigData, selectReviewConfigVisualAssets } from '../../redux/reviewConfig/reviewConfigSelectors';
import { useSelector } from 'react-redux';
import { VisualAssetsConfig } from '../../models/ReviewConfig';

// this component is used in multiple pages that require different layouts so it's a bit messy
// for design rules see this figma file https://www.figma.com/file/wCPXJFNvXvkfWDehYJPHeV/Review-flows---WEBAPP?node-id=3242%3A30032&t=bNyLiZfLIjyOvwLN-1

interface Props {
  headerIsInline?: boolean;
  page?: 'thank-you' | 'table' | 'splash';
}

export const BrandContainer: FC<PropsWithChildren<Props>> = ({ children, headerIsInline, page }) => {
  const config = retrieveDecodedToken()?.cfg;
  const configSelector = useSelector(selectReviewConfigData);
  const visualAssetsConfig = useSelector(selectReviewConfigVisualAssets) as VisualAssetsConfig;
  const { handleLogoClick } = useSkipReview();
  const billId = getBillId();
  const needViewCheckBtn = !!(billId && page !== 'table');

  const headerClassList = classNames(
    'yum-brand-header',
    { inline: headerIsInline },
    { 'thank-you': page === 'thank-you' },
    { 'table-page': page === 'table' }
  );
  const logoClassList = classNames('yum-brand-logo', { centered: !needViewCheckBtn });

  if (!config) {
    return <Redirect to="/entry" />;
  }

  const { bg: backgroundImage, overlay, bgLogo: logo } = visualAssetsConfig;
  const venueName = configSelector?.venueName;

  return (
    <>
      <div
        className="yum-brand-background"
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
          backgroundColor: backgroundImage ? undefined : getColorVar('grey4'),
        }}
      >
        <div className="yum-brand-background-overlay" style={{ opacity: overlay + '%' }} />
      </div>
      <div className="yum-brand-main-content">
        <div className={headerClassList}>
          {venueName && page === 'splash' && <p className="yum-splash-venue-name">{venueName}</p>}
          {logo && <img className={logoClassList} alt="Logo" src={logo} onClick={handleLogoClick} />}
          {needViewCheckBtn && <ViewCheck />}
        </div>
        {children}
      </div>
    </>
  );
};
